import { Component, OnInit } from '@angular/core';
import {CustomerService} from '../../services/customer.service';
import {Customer} from '../../../models/customer.model';
import {AuthenticationService} from '../../services/authentication.service';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-top-header',
  templateUrl: './top-header.component.html',
  styleUrls: ['./top-header.component.scss']
})
export class TopHeaderComponent implements OnInit {

  customer: Customer;
  selected: string;
  langs: any;

  constructor(private customerService: CustomerService,
              private authService: AuthenticationService,
              private router: Router,
              private translate: TranslateService
              )
  { }

  ngOnInit(): void {
    this.customer = this.customerService.customer;
    this.selected = './assets/flags/' + this.translate.currentLang + '.png';
    this.langs = environment.lang;
  }

  signOff() {
    this.authService.SignOff();
    this.router.navigate(['']);
  }

  changeLang(lang: string) {
    this.customerService.changeLang(lang);
    this.selected = './assets/flags/' +  lang + '.png';
  }
}
