import { Product } from './product.model';
import { Address } from './address.model';
import { OrderLine } from './orderLine.model';

export class Order {


  // constructor(
  //   orderID: string,
  //   orderDate: Date,
  //   shippingDate: Date,
  //   content: OrderLine[],
  //   shippingAddress: Address,
  //   total: number,
  //   validated: boolean,
  //   comment: string
  // )
  constructor(
    public documentReference?: string,
    public orderDate?: Date,
    public shippingDate?: Date,
    public lines?: OrderLine[],
    public shipToAddresses?: Address,
    public total?: number,
    public validated?: boolean,
    public comment?: string,
    public filename?: string,
  ) {
    if (documentReference == null) {
      this.documentReference = '';
    }
    if (orderDate == null) {
      this.orderDate = new Date();
    }
    if (shippingDate == null) {
      this.shippingDate = new Date();
    }
    if (lines == null) {
      this.lines = [new OrderLine()];
    }
    if (shipToAddresses == null) {
      this.shipToAddresses = new Address();
    }
    if (total == null) {
      this.total = 0;
    }
    if (validated == null) {
      this.validated = false;
    }
    if (comment == null) {
      this.comment = '';
    }
    if (filename == null) {
      this.filename = '';
    }

  }
}
