import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';


@Injectable()
export class Globals {

  apiRoute = environment.apiUrl;
 headers = {
   'Content-Type': 'application/json',
   'Accept': 'application/json',
   //'Access-Control-Allow-Credentials': 'true',
   //'Access-Control-Allow-Origin': this.apiRoute
 };
 options = {
   headers : this.headers,
   //withCredentials: true,
 };

}
