import {Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation} from '@angular/core';
import { Subscription } from 'rxjs';
import { Order } from '../../../models/order.model';
import { CustomerService } from '../../services/customer.service';
import { Router } from '@angular/router';
import {Title} from '@angular/platform-browser';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {isNotNullOrUndefined} from 'codelyzer/util/isNotNullOrUndefined';
import {TranslateService} from '@ngx-translate/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MatSnackBar} from "@angular/material/snack-bar";

@UntilDestroy()
@Component({
  selector: 'app-orders-list',
  templateUrl: './list.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./list.component.scss']
})
export class OrdersListComponent implements OnInit, OnDestroy {

  LOCALE_ID: any;
  lastID: string;
  statusNotValidated: string;
  statusValidated: string;
  partnerName: string;

  // Subscription to current customer's orders (got from SAS) & array to store 'em
  ordersSubscription: Subscription;
  ordersList: Order[] = [];
  interval_id: number;
  @ViewChild('modalConfirm')
  private elModalConfirm: ElementRef;

  constructor(private customerService: CustomerService,
              private router: Router,
              private titleService: Title,
              private translate: TranslateService,
              private modal: NgbModal) { }

  ngOnInit() {

    // Translation settings
    this.translate.get('LIST.TITLE')
      .pipe(untilDestroyed(this))
      .subscribe((res: string) => this.titleService.setTitle(res));
    this.translate.get('LIST.STATUSOPTIONS.UNVALIDATED')
      .pipe(untilDestroyed(this))
      .subscribe((res: string) => this.statusNotValidated = res);
    this.translate.get('LIST.STATUSOPTIONS.VALIDATED')
      .pipe(untilDestroyed(this))
      .subscribe((res: string) => this.statusValidated = res);

    // Init lastID to none order
    this.lastID = null;

    // Subscription to customer service with auto destroy
    this.ordersSubscription = this.customerService.ordersSubject
      .pipe(untilDestroyed(this))
      .subscribe(
      (orders: Order[]) => {
          this.ordersList = orders;
          console.log(orders);
      }
    );
    this.customerService.emitOrdersSubject();

    this.customerService.getCustomerOrdersList();

/*    if (this.customerService.customer.shipToAddresses.length === 0) {
      // this.customerService.getCustomerShipToAddresses();
      this.customerService.getCustomerOrdersList();
    }*/
     // Set interval to refresh table
     this.interval_id = window.setInterval(() => {this.customerService.getCustomerOrdersList(); }, 5000);

     this.partnerName = this.customerService.token.name;
  }

    ngOnDestroy(): void {
        window.clearInterval(this.interval_id);
    }

  // Set LastID with index of an order to display info Card

  setDelete(index: string) {
    this.modal.open(this.elModalConfirm, {centered: true, size: 'sm'});
    this.lastID = index;
  }

  onDeleteOrder() {
    if (isNotNullOrUndefined(this.lastID)) {
      this.customerService.deleteOrder(this.customerService.getSingleOrderLocal(this.lastID));
      this.lastID = null;
      this.refreshData();
    }
  }

  clear() {
    this.lastID = null;
  }

  refreshData(time = 50) {
    // Orders list must be emptied before getting new data (plan to change ag-table for another npm package)
    const save = this.ordersList;
    this.ordersList = [];
    setTimeout(() => {this.ordersList = save; }, time );
  }


}
