export class Address {

  // constructor(
  //   number: string,
  //   street: string,
  //   city: string,
  //   postalCode: string,
  //   name: string
  // )
  constructor(
    public address?: string,
    public city?: string,
    public postalCode?: string,
    public countryRegionCode?: string,
    public name?: string,
    public fullname?: string,
    public email?: string,
    public phoneNumber?: string,
    public gln?: string
  ) {
    if (address == null) {
      this.address = '';
    }
    if (city == null) {
      this.city = '';
    }
    if (postalCode == null) {
      this.postalCode = '';
    }
    if (name == null) {
      this.name = 'Personnalisée';
    }
    if (fullname == null) {
      this.fullname = '';
    }
    if (email == null) {
      this.email = '';
    }
    if (countryRegionCode == null) {
      this.countryRegionCode = '';
    }
    if (phoneNumber == null) {
      this.phoneNumber = '';
    }
    if (gln == null) {
      this.gln = '';
    }
  }
}
