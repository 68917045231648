<!-- If user is connected -->
<!---->
  <div id="page-top" *ngIf="isAuth" style="position:absolute; width:100%; height:100%">
    <!-- Page Wrapper -->
    <div id="wrapper">

<!--      <app-header></app-header>-->

      <!-- Content Wrapper -->
      <div id="content-wrapper" class="d-flex flex-column">

        <!-- Main Content -->
        <div id="content">
          <div>
            <app-top-header></app-top-header>
          </div>


          <!-- Begin Page Content -->
          <div class="container-fluid">
            <router-outlet></router-outlet>
          </div>
        </div>
        <!-- End of Main Content -->

      </div>
      <!-- End of Content Wrapper -->

    </div>
    <!-- End of Page Wrapper -->
    <!-- Scroll to Top Button-->

  </div>

<!-- If user is not connected -->

  <div class="bg-gradient-primary" *ngIf="!isAuth" style="position:absolute; width:100%; height:100%">
    <div class="container">

      <!-- Outer Row -->
      <div class="row justify-content-center">

        <div class="col-xl-10 col-lg-12 col-md-9">

          <div class="card o-hidden border-0 shadow-lg my-5">
            <div class="card-body p-0">
              <!-- Nested Row within Card Body -->
              <div class="row">
                <div class="col-lg-6 d-none d-lg-block bg-login-image"></div>
                <div class="col-lg-6">
                  <div class="p-5">
                    <div id="langSelect" class="dropdown no-arrow " style="position: absolute; top: 2px; right: 40px">
                      <a class=" dropdown-toggle" id="flagDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <img width="20px" height="20px" [src]="selected">
                      </a>
                      <!-- Dropdown - User Information -->
                      <div style="min-width: 0px;" class="dropdown-menu border-0 bg-transparent" aria-labelledby="flagDropdown">
                        <a class="dropdown-item bg-transparent" *ngFor="let lang of langs" (click)="changeLang(lang)">
                          <img width="20px" height="20px" src="./assets/flags/{{lang}}.png">
                        </a>
                      </div>
                    </div>
                    <div class="text-center">
                      <h1 class="h4 text-gray-900 mb-4" i18n="Welcome@@logWelcome">{{'LOGIN.MAIN.logWelcome' | translate}}</h1>
                    </div>
                    <form class="user"  [formGroup]="authForm">
                      <div [ngClass]="{'form-group': true, 'is-invalid': error === true}">
                        <input formControlName="login"
                               (keydown.enter)="onSignIn()"
                               type="text"
                               [ngClass]="{'form-control form-control-user my-2': true, 'is-invalid': error === true}"
                               id="exampleInputEmail"
                               aria-describedby="emailHelp"
                               i18n-placeholder="ID placeholder@@idPlaceHolder"
                               placeholder="{{'LOGIN.MAIN.idPlaceHolder' | translate }}">

                        <input (keydown.enter)="onSignIn()"
                               formControlName="password"
                               type="password"
                               [ngClass]="{'form-control form-control-user': true, 'is-invalid': error === true}"
                               id="exampleInputPassword"
                               i18n-placeholder="Password placeholder@@pwdPlaceholder"
                               placeholder="{{'LOGIN.MAIN.pwdPlaceholder' | translate }}">
                      </div>
                      <div class="invalid-feedback text-right">
                        {{errorMessage}}
                      </div>
                      <div class="form-group">
                        <div class="custom-control custom-checkbox small">
                          <input type="checkbox" class="custom-control-input" id="customCheck">
                          <label class="custom-control-label" for="customCheck" i18n="Remember me label checkbox@@rememberMeCheckBox">{{'LOGIN.MAIN.rememberMeCheckBox' | translate}}</label>
                        </div>
                      </div>
                      <a routerLink="" (click)="onSignIn()"  class="btn btn-primary btn-user btn-block">
                        <span *ngIf="!isLoading" i18n="Login button label@@loginBtn">{{'LOGIN.MAIN.loginBtn' | translate}}</span>
                        <span *ngIf="isLoading" i18n="Loading text@@loadingTxt">{{'LOGIN.MAIN.loadingTxt' | translate}}</span>
                      </a>

                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>

    </div>
  </div>
