import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from './services/authentication.service';
import {Subscription} from 'rxjs';
import { Title } from '@angular/platform-browser';
import {FormBuilder, FormGroup} from '@angular/forms';
import {HttpErrorResponse} from '@angular/common/http';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {TranslateService} from '@ngx-translate/core';
import {environment} from "../environments/environment";
import {CustomerService} from "./services/customer.service";

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {

  authForm: FormGroup;
  isAuth: boolean;
  authSub: Subscription;
  isLoading: boolean;
  unrecognized: string;
  toFill: string;
  error: boolean;
  errorMessage: string;
  selected: string;
  langs: any;
  title: any;

  constructor(private authService: AuthenticationService,
              private titleService: Title,
              private formBuilder: FormBuilder,
              private translate: TranslateService,
              private customerService: CustomerService) {
  }

  ngOnInit() {
    this.title = "CustomerPortal";
    this.changeLang(this.translate.getBrowserLang());
    this.langs = environment.lang;
    // Translation settings
    this.translate.get('APP.TITLE')
      .pipe(untilDestroyed(this))
      .subscribe((res: string) => this.titleService.setTitle(res));
    this.translate.get('APP.UNRECOGNIZED')
      .pipe(untilDestroyed(this))
      .subscribe((res: string) => this.unrecognized = res);
    this.translate.get('APP.TOFILL')
      .pipe(untilDestroyed(this))
      .subscribe((res: string) => this.toFill = res);

    // Initialize others variables
    this.isAuth = true;
    this.isLoading = false;
    this.error = false;
    this.errorMessage = '';

    // Creation subscription to observe isAuth variable state from Authentication Service variable and emit a first time
    this.authSub = this.authService.isAuthSub
      .pipe(untilDestroyed(this))
      .subscribe(
      (value) => {
        this.isAuth = value;
      });
    this.authService.emitSubject();

    this.initForm();

    // Create an configuration snack bar
  }

  onSignIn() {
    if (this.authForm.get('login').value !== '' && this.authForm.get('password').value !== '') {
      this.isLoading = true;
      this.authService.SignIn(this.authForm.get('login').value, this.authForm.get('password').value)
        .then((response) => {
          if (response instanceof HttpErrorResponse) {
            this.error = true;
            this.errorMessage = this.unrecognized;
          }
          this.isLoading = false;
        });
    } else {
      this.errorMessage = this.toFill;
      this.error = true;
      this.isLoading = false;
    }

  }

  initForm() {
    this.authForm = this.formBuilder.group({
        login: '',
        password: ''
      }
    );
  }

  changeLang(lang: string) {
    this.customerService.changeLang(lang);
    this.selected = './assets/flags/' +  lang + '.png';
  }
}
