import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { formatDate } from '@angular/common';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
  AsyncValidatorFn,
  AbstractControl,
  ValidationErrors,
  FormControl
} from '@angular/forms';
import { CustomerService } from '../../services/customer.service';
import { Customer } from '../../../models/customer.model';
import { Order } from '../../../models/order.model';
import { OrderLine } from '../../../models/orderLine.model';
import { Product } from '../../../models/product.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Address } from '../../../models/address.model';
import {Title} from '@angular/platform-browser';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {debounceTime} from 'rxjs/operators';
import {isNotNullOrUndefined} from 'codelyzer/util/isNotNullOrUndefined';
import { MatSnackBar} from '@angular/material/snack-bar';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Globals} from '../../utils/globals';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';


@UntilDestroy()
@Component({
  selector: 'app-order',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class OrderComponent implements OnInit {

  // Order formular controls holder & customer infos
  orderForm: FormGroup;
  customer: Customer;

  // Order form variables
  pageTitle: string;
  orderDate: string;
  shippingDate: string;
  currentDisplayAddress: Address;

  // Array to store results from a product research (called by Ean Validator)
  researchResults: string[];

  // Last visited EAN input index of content FormArray control
  lastVisitedInput: FormGroup;

  // Value of last focused EAN input
  lastFocusedInputValues: any;

  // Variables holding each URL parameter value. (action) to perform and (id) of targeted order
  action: string;
  documentReference: string;

  // Custom address constant
  readonly customAddressName = 'Personnalisée';

  // Empty order to store new/passed order
  currentOrder: Order;

  // Load variables
  isFormReady: boolean;
  isAddressLoaded: boolean;
  isOrderLoaded: boolean;
  unvalidLineMessage: string;

  //error to show on popup
  error: string;
  @ViewChild('errorModal')
  private elErrorModal: ElementRef;

  constructor(
    private formBuilder: FormBuilder,
    private customerService: CustomerService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    public snackBar: MatSnackBar,
    private httpClient: HttpClient,
    private globals: Globals,
    private translate: TranslateService,
    private elementREf: ElementRef,
    private modal: NgbModal  ) {
  }

  ngOnInit() {
    // Init variables to prevent formular elements to load before data
    this.isFormReady = false;
    this.isAddressLoaded = false;
    this.isOrderLoaded = false;
    this.lastFocusedInputValues = {};

    // Set component page title
    this.translate.get('CREATE.TITLE')
      .pipe(untilDestroyed(this))
      .subscribe((res: string) => this.titleService.setTitle(res));

    // Subscription to any change on URL parameters
    this.activatedRoute.paramMap
      .pipe(untilDestroyed(this))
      .subscribe(paramMap => {
      this.documentReference = paramMap.get('id');
      this.action = paramMap.get('action');

      // Translation settings
      this.translate.get('CREATE.UNVALIDLINEMESSAGE')
          .pipe(untilDestroyed(this))
          .subscribe((res: string) => this.unvalidLineMessage = res);

      switch (this.action) {
        case 'new':
          this.translate.get('CREATE.CREATION')
            .pipe(untilDestroyed(this))
            .subscribe((res: string) => this.pageTitle = res);
          break;
        case 'view':
          this.translate.get('CREATE.VIEW')
            .pipe(untilDestroyed(this))
            .subscribe((res: string) => this.pageTitle = res);
          break;
        case 'modify':
          this.translate.get('CREATE.MODIFY')
            .pipe(untilDestroyed(this))
            .subscribe((res: string) => this.pageTitle = res);
          break;
        default:
          // If passed parameters are wrong
          this.router.navigate(['orders', 'list']);
          break;
      }

      this.customerService.getCustomerShipToAddresses()
          .then(() => {
            // Get a reference to customer with addresses array
            this.isAddressLoaded = true;
            this.customer = this.customerService.customer;

            // Init the component after getting customer addresses
            this.initComponent();

          });

    });

  }

  initComponent() {

    // Init research results array
    this.researchResults = [];

    if (this.action === 'modify') {
      // Get a single Order from Customer service

      this.customerService.getSingleOrder(this.documentReference)
        .then(order => {
          // Get a reference to the order to modify
          this.currentOrder = order;

          // If a wrong parameter is passed to url we go back
          if (this.currentOrder === undefined) {
            this.router.navigate(['orders', 'list']);
          }

          /* If custom address is selected/set (for an order editing), this custom address is
          temporary stored into Customer object of the component (for later purpose cf:setAddressFields)
           */
          if (this.currentOrder.shipToAddresses.name === this.customAddressName) {
            const index = this.customer.shipToAddresses.findIndex(address => address.name === this.customAddressName);
            this.customer.shipToAddresses[index] = this.getCopy(this.currentOrder.shipToAddresses);
          }



          this.initFields();

          this.setGlobalTotal();
        });
    } else {
      this.currentOrder = new Order();
      this.currentOrder.shipToAddresses.name = 'Personnalisée';
      this.initFields();

    }
  }


  initFields() {

    // Init formular
    this.initForm();
    // Set address fields
    this.setAddress(this.currentOrder.shipToAddresses.name);


    // Fills each FormGroup (and FormControl) of content ArrayForm
    for (let i = 0; i < this.currentOrder.lines.length; i++) {
      this.setOrderLine(this.orderForm.get('content.' + i) as FormGroup, this.currentOrder.lines[i]);
      this.getContent().enable({emitEvent: false});
    }

    if (this.currentOrder.shipToAddresses.name === this.customAddressName) {
      // Init values of Address Form Group
      for (const field in this.currentOrder.shipToAddresses) {
        if (this.getAddressGroup().get(field) !== null) {
          this.getAddressGroup().get(field).setValue(this.currentOrder.shipToAddresses[field]);
        }
      }
    }

    // Init document reference field
    this.orderForm.get('documentReference').setValue(this.currentOrder.documentReference);

    // Set date fields
    this.setDateFields();
    this.orderForm.updateValueAndValidity();
  }

  setDateFields() {
    // We retrieve local date and format it to set date values (orderDate & shippingDate)
    registerLocaleData(localeFr, 'fr-FR');
    // We format each date in the right way
    this.shippingDate = formatDate(this.currentOrder.shippingDate, 'yyyy-MM-dd', 'fr-FR');
    this.orderDate = formatDate(this.currentOrder.orderDate, 'yyyy-MM-dd', 'fr-FR');

    this.orderForm.get('shippingDate').setValue(this.shippingDate);
    this.orderForm.get('orderDate').setValue(this.orderDate);
  }

  // When user change address selecion it modifies address fields and display custom address fields
  setAddress(addressName: string) {
    // Check if undefined?

    const selectedAddress = this.customer.shipToAddresses.find(address => address.name === addressName);
    const addressFields = this.getAddressGroup();

    addressFields.get('name').setValue(addressName);

    if (addressName === this.customAddressName) {
      addressFields.enable({emitEvent: false});
      for (let name in addressFields.controls) {
        if (name !== this.customAddressName) {
          (addressFields.controls[name] as FormControl).setValue('');
        }
        addressFields.get('name').setValue(this.customAddressName);
      }
    } else {
      addressFields.disable({emitEvent: false});
      addressFields.get('address').setValue(selectedAddress.address);
      addressFields.get('city').setValue(selectedAddress.city);
      addressFields.get('postalCode').setValue(selectedAddress.postalCode);
      addressFields.get('countryRegionCode').setValue(selectedAddress.countryRegionCode);
      addressFields.get('phoneNumber').setValue(selectedAddress.phoneNumber);
    }
    this.currentDisplayAddress = selectedAddress;

  }


  initForm() {
    // We create each controls of formular & then indicates it's ready to be displayed
    this.orderForm = this.formBuilder.group({
      documentReference: ['', Validators.required],
      orderDate: [{value: '', disabled: true}, Validators.required],
      shippingDate: ['', Validators.required],
      content: this.formBuilder.array([]),
      address: this.formBuilder.group({
        address: [{value: '', disabled: true}, Validators.required],
        city: [{value: '', disabled: true}, Validators.required],
        postalCode: [{value: '', disabled: true}, Validators.required],
        name: [{value: '', disabled: true}],
        countryRegionCode: [{value: '', disabled: true}],
        fullname: [{value: '', disabled: true}, Validators.required],
        email: [{value: '', disabled: true}, [Validators.required, Validators.email]],
        phoneNumber: [{value: '', disabled: true}, Validators.required],
      }),
      total: [{value: '', disabled: true}, Validators.required],
      comment: ['']
    });
    this.isFormReady = true;


    // We fill 'content' FormArray with as many as products there are in the currentOrder (1 product for new order)
    // Before we store current content lentgh because it might (de)increase in the loop
    const currentLength = this.currentOrder.lines.length;
    for (let i = 0; i < currentLength; i++) {
      this.addContent();
    }
    this.isOrderLoaded = true;

  }

  /*
   * Events functions
   */

  // When an input of ArrayForm groups is focused on we get its index
  setVisited(control: AbstractControl) {
    this.lastVisitedInput = control as FormGroup;
    this.lastFocusedInputValues.ean = this.lastVisitedInput.get('ean').value;
    this.lastFocusedInputValues.itemNo = this.lastVisitedInput.get('itemNo').value;

  }


  // When focus out from a ean input if value is modified by an invalid value or if a research result is not selected
  // we put again last value of input
  onFocusOut(control: AbstractControl) {
    control = control as FormGroup;

    setTimeout(() => {
      if ( (control.get('ean').value !== this.lastFocusedInputValues.ean || control.invalid) || (control.get('itemNo').value !== this.lastFocusedInputValues.itemNo || control.invalid)) {
        control.get('ean').setValue(this.lastFocusedInputValues.ean, {emitEvent: false});
        control.get('itemNo').setValue(this.lastFocusedInputValues.itemNo, {emitEvent: false});
      }
      this.clear();
    }, 250);

  }

  // We clear last retains index and research results when focus out (to clear UI)
  clear() {
    this.getContent().updateValueAndValidity();
    this.researchResults = [];
  }


  // Fill an order line in formular from a OrderLine (object) data
  setOrderLine(content: FormGroup, orderLine: OrderLine, emitEANEvent = false) {

    content.get('ean').setValue(orderLine.product.EAN, {emitEvent: emitEANEvent});
    content.get('description').setValue(orderLine.product.description);
    content.get('pcb').setValue(orderLine.product.pcb);
    content.get('price').setValue(orderLine.product.unitPrice);
    content.get('quantity').setValue(orderLine.quantity);
    content.get('itemNo').setValue(orderLine.product.itemNo, { emitEvent: emitEANEvent });
  }

  onProductSelect(product: Product) {

    // When select a product we focus out so we hae to avoid getting the last value
    this.lastFocusedInputValues = { ean: product.EAN, itemNo: product.itemNo };

    // search for corresponding product & set Orderline instance (with one unit of product, at least)
    // const product = this.customerService.mokingProducts.find(product => product.EAN == productEAN);
    const orderLine = new OrderLine(product, product.pcb, product.unitPrice * product.pcb);

    // We set order line in formular with retrieve data
    this.setOrderLine(this.lastVisitedInput as FormGroup, orderLine, false);

    this.lastVisitedInput.get('quantity').enable();

    // We clear research results
    this.clear();
  }


  // Set global total
  setGlobalTotal() {
    let globalTotal = 0.0;

    for (const orderLine of this.getContent().controls) {
      if (!isNaN(orderLine.get('total').value)) {
        globalTotal += parseFloat(orderLine.get('total').value);
      }
    }
    this.orderForm.get('total').patchValue(globalTotal, {emitEvent: false});

  }


  // Set an order line total by getting price and quantity values
  setLineTotal(control: FormGroup) {

    const price = parseFloat(control.get('price').value);
    const quantity = parseFloat(control.get('quantity').value);
    if (!isNaN(price) && !isNaN(quantity)) {
      control.get('total').patchValue(price * quantity, {emitEvent: false});
    }

  }

  submitOrder() {

    if (this.orderForm.invalid)
    {
      this.snackBar.open('Formular is invalid');
      return;
    }

    this.currentOrder.filename =
      'cmdportailclientFS_' +
      formatDate(this.currentOrder.orderDate, 'yyyyMMdd_hhmm_', 'fr-FR') +
      this.orderForm.get('documentReference').value +
      '.csv';

    if (this.currentOrder.documentReference === '') {
      this.currentOrder.documentReference = this.orderForm.get('documentReference').value;
    }

    this.currentOrder.orderDate = new Date(this.orderForm.get('orderDate').value);
    this.currentOrder.shippingDate = new Date(this.orderForm.get('shippingDate').value);

    const addressGroup = this.getAddressGroup();
    this.currentOrder.shipToAddresses = {
      name: addressGroup.get('name').value,
      address: addressGroup.get('address').value,
      city: addressGroup.get('city').value,
      postalCode: addressGroup.get('postalCode').value,
      countryRegionCode: addressGroup.get('countryRegionCode').value,
    };

    if (addressGroup.get('name').value === this.customAddressName) {
      this.currentOrder.shipToAddresses = {
        name: addressGroup.get('name').value,
        address: addressGroup.get('address').value,
        city: addressGroup.get('city').value,
        postalCode: addressGroup.get('postalCode').value,
        countryRegionCode: addressGroup.get('countryRegionCode').value,
        fullname: addressGroup.get('fullname').value,
        email: addressGroup.get('email').value,
        phoneNumber: addressGroup.get('phoneNumber').value
      };
    }

    this.currentOrder.lines = [];

    const content = this.getContent();
    for (let i = 0; i < content.length; i++) {
      const val = content.get(i + '');
      const orderLine = new OrderLine(
        new Product(
          val.get('ean').value,
          val.get('description').value,
          val.get('pcb').value,
          val.get('price').value,
          val.get('itemNo').value
        ),
        val.get('quantity').value,
        val.get('total').value
      );
      this.currentOrder.lines.push(orderLine);
    }
    console.log(this.currentOrder);
    this.currentOrder.total = this.orderForm.get('total').value;
    this.customerService.submitOrder(this.currentOrder);

    this.router.navigate(['orders', 'list']);
  }


  // Utils/////


  // 'content' FormArray utilities

  // Get nested FormArray of main Form
  getContent() {
    return this.orderForm.get('content') as FormArray;
  }

  getContentField(control: string, index: number) {
    return this.getContent().get(index + '.' + control);
  }

  // Add a GroupForm to 'content' nested FormArray of main Form
  addContent(fromUI = false) {

    if (fromUI && this.getContent().invalid) {
        this.snackBar.open(this.unvalidLineMessage, null, {duration: 3000});
        return;
    }
    const newContentGroup = this.formBuilder.group({
      ean:
        ['',
          [Validators.required, Validators.pattern(/[0-9]{13}/)],
          [this.contentAsyncValidator()]
        ],
      itemNo: ['', [Validators.required, Validators.pattern(/[0-9]{6}/)] ],
      description: [{value: '', disabled: true}, Validators.required],
      pcb: [{value: '', disabled: true}, Validators.required],
      price: [{value: '', disabled: true}, Validators.required],
      quantity: [{value: '', disabled: true}, Validators.required],
      total: [{value: '', disabled: true}, Validators.required]
    });

    // Set subscription on each ean value change
    newContentGroup.get('ean').valueChanges.pipe(
      debounceTime(250),
      untilDestroyed(this)
    ).subscribe(next => {
      this.lastVisitedInput = newContentGroup;

      const value = next.toString().replace(' ', '');
      if (isNotNullOrUndefined(value) && value.length === 13) {
        this.productResearch(value, this.currentDisplayAddress.name === this.customAddressName ? this.customer.lddCustomerPriceGroup : this.customer.customerPriceGroup);
      }
    });

    newContentGroup.get('itemNo').valueChanges.pipe(
      debounceTime(250),
      untilDestroyed(this)
    ).subscribe(next => {
      this.lastVisitedInput = newContentGroup;

      const value = next.toString().replace(' ', '');
      if (isNotNullOrUndefined(value) && value.length === 6) {
        this.productResearchByItemNo(value, this.currentDisplayAddress.name === this.customAddressName ? this.customer.lddCustomerPriceGroup : this.customer.customerPriceGroup);
      }
    });

    newContentGroup.get('quantity').valueChanges.pipe(
      untilDestroyed(this)
    ).subscribe((value) => {

      this.setLineTotal(newContentGroup as FormGroup);
      this.setGlobalTotal();
    });

    this.getContent().insert(this.getContent().length, newContentGroup);
    if (fromUI) {
      document.getElementById('endOfTable').scrollIntoView({behavior: 'auto'});
    }
    newContentGroup.updateValueAndValidity();
  }

  deleteContent(index: number) {

    // When a line is delete we remove a FormGroup from 'content' FormArray & a line from Order's OrderLine
    if (this.getContent().length > 1) {
      this.getContent().removeAt(index);
      this.setGlobalTotal();
    }
  }

  getAddressGroup(): FormGroup {
    return this.orderForm.get('address') as FormGroup;
  }

  productResearch(EAN: string, salesPriceId: string) {
    const temporaryResults = [];
    const params = new HttpParams()
      .append('EAN', EAN)
      .append('salesPriceId', salesPriceId);

    this.httpClient.get(
      this.globals.apiRoute + '/Items/GetProductDataByEANAndSalesPriceId',
      Object.assign(this.globals.options, {params})
    )
      .toPromise()
      .then(result => {
        if ((result as any).itemNo !== null) {
          const product = new Product();
          product.EAN = (result as any).ean;
          product.unitPrice = (result as any).unitPrice;
          product.itemNo = (result as any).itemNo;
          product.description = (result as any).description;
          product.pcb = (result as any).pcb;

          temporaryResults.push(deepCopy(product));
        }
        else {
          this.error = "Produit non trouvé dans le groupe prix client \"" + salesPriceId + "\"";
          this.ShowError();
        }
        this.researchResults = temporaryResults;
        return this.researchResults.length > 0;
      });
  }

  productResearchByItemNo(itemNo: string, salesPriceId: string) {
    const temporaryResults = [];
    const params = new HttpParams()
      .append('itemNo', itemNo)
      .append('salesPriceId', salesPriceId);

    this.httpClient.get(
      this.globals.apiRoute + '/Items/GetProductDataByItemNoAndSalesPriceId',
      Object.assign(this.globals.options, { params })
    )
      .toPromise()
      .then(result => {
        console.log(result);
        if ((result as any).itemNo !== null) {
          const product = new Product();
          product.EAN = (result as any).ean;
          product.unitPrice = (result as any).unitPrice;
          product.itemNo = (result as any).itemNo;
          product.description = (result as any).description;
          product.pcb = (result as any).pcb;

          temporaryResults.push(deepCopy(product));
        }
        else {
          this.error = "Produit non trouvé dans le groupe prix client \"" + salesPriceId + "\"";
          this.ShowError();
        }
        this.researchResults = temporaryResults;
        return this.researchResults.length > 0;
      });
  }

  // Make an EAN input Form Control valid only if other fields are filled
  contentAsyncValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> => {
      return new Promise(
        (resolve, reject) => {
          Object.keys(control.parent.controls).forEach(ctrl => {
            if (control.parent.get(ctrl).value === '') {
              resolve({notfound: true});
            }
          });
          //resolve();

        }
      );
    };
  }


  getBack() {
    if (this.action === 'modify') {
      this.submitOrder();
    }
    this.router.navigate(['orders', 'list']);
  }

  getCopy(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  ShowError() {
    this.modal.open(this.elErrorModal, { centered: true });
  }

}

/**
 * Deep copy function for TypeScript.
 * @param T Generic type of target/copied value.
 * @param target Target value to be copied.
 * @see Source project, ts-deepcopy https://github.com/ykdr2017/ts-deepcopy
 * @see Code pen https://codepen.io/erikvullings/pen/ejyBYg
 */
export const deepCopy = <T>(target: T): T => {
  if (target === null) {
    return target;
  }
  if (target instanceof Date) {
    return new Date(target.getTime()) as any;
  }
  if (target instanceof Array) {
    const cp = [] as any[];
    (target as any[]).forEach((v) => { cp.push(v); });
    return cp.map((n: any) => deepCopy<any>(n)) as any;
  }
  if (typeof target === 'object' && target !== {}) {
    const cp = { ...(target as { [key: string]: any }) } as { [key: string]: any };
    Object.keys(cp).forEach(k => {
      cp[k] = deepCopy<any>(cp[k]);
    });
    return cp as T;
  }
  return target;
};
