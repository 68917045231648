<form [formGroup]="orderForm" (ngSubmit)="submitOrder()" class="container-fluid" *ngIf="isFormReady">

  <div class="row">
    <h1 class="col">{{pageTitle}}</h1>
  </div>

  <!--  form-horizontal3.3.-->
  <!-- Creation & Shipping dates div -->
  <div class="row">
    <div class="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 flex-grow-1 my-2">
      <div class="card shadow border-left-info " id="datesInfoCard">
        <div class="card-header m-0 font-weight-bold text-primary">
          <h2 class="font-weight-bold text-primary"
              i18n="Info card title for dates@@ordersDatesCardTitle">
            {{'ORDERS.CREATE.ordersDatesCardTitle' | translate}}
          </h2>
        </div>
        <div class="card-body col-12 row">
          <h3>
            <span class="badge btn-secondary" i18n="Order reference label@@orderReference">{{'ORDERS.CREATE.orderReference' | translate}}</span>
          </h3>
          <input type="text" name="orderRef" class="form-control" formControlName="documentReference">
          <h3>
            <span class="badge btn-secondary" i18n="Order date label@@orderDate">{{'ORDERS.CREATE.orderDate' | translate}}</span>
          </h3>
          <input type="date" name="orderDate" class="form-control" formControlName="orderDate">
          <h3>
            <span class="badge btn-secondary "
                  i18n="Shipping date label@@shippingDate">{{'ORDERS.CREATE.shippingDate' | translate}}</span>
          </h3>
          <input type="date"
                 name="shippingDate"
                 class="form-control "
                 formControlName="shippingDate"
                 [min]="orderDate">
        </div>
      </div>
    </div>

    <!-- Address div -->
    <div class="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 flex-grow-1 my-2" *ngIf="isAddressLoaded">
      <div class="card shadow border-left-info" id="addressInfoCard">
        <!-- Card Header - Dropdown -->
        <div class="card-header align-items-center justify-content-between">
          <h2 class="font-weight-bold text-primary" i18n="Customer's address fields title@@customerAddressFieldsTitle">
            {{'ORDERS.CREATE.customerAddressFieldsTitle' | translate}}
          </h2>
        </div>
        <div class="card-body row">
          <div class="col-12 col-xs-12 col-md-4 col-lg-4 col-xl-4 overflow-auto">
            <ul class="list-group ">
              <li *ngFor="let address of customer.shipToAddresses"
                  [ngClass]="{'list-group-item list-group-item-action' : true, 'active' : address.name === orderForm.get('address.name').value}">
                <a class="btn" (click)="setAddress(address.name)">
                  <ng-container *ngIf="address.name !== customAddressName">
                    {{address.name}}
                  </ng-container>
                  <ng-container *ngIf="address.name === customAddressName">
                    {{'ORDERS.CREATE.customAddressLabel' | translate}}
                  </ng-container>
                </a>
              </li>
            </ul>
          </div>
          <div class="col-12 col-xs-12 col-md-8 col-lg-8 col-xl-8" *ngIf="isAddressLoaded">
            <div class="col-12">
              <!-- Card Header - Accordion -->
              <!--                <h6 class="m-0 font-weight-bold text-primary" i18n="Custom shipping address fields title@@customAddressFieldsTitle">Shipping address</h6>-->
              <!-- Card Content - Collapse -->
              <div class="row" id="customAddressCard" formGroupName="address">
                <div class="col-12 col-xs-12 col-md-12 col-lg-6 col-xl-6" *ngIf="currentDisplayAddress.name === customAddressName">
                  <div class="row" id="namesDiv border">
                    <div class="col-12 col-md" id="fullnameDiv">
                      <h3><span class="badge btn-secondary" i18n="Fullname label@@fullname">{{'ORDERS.CREATE.fullname' | translate}}</span></h3>
                      <input type="text" id="fullname" name="fullname" formControlName="fullname"
                             class="form-control">
                    </div>
                  </div>
                  <div class="row" id="emailDiv">
                    <div class="col">
                      <h3><span class="badge btn-secondary" i18n="Email label@@email">{{'ORDERS.CREATE.email' | translate}}</span></h3>
                      <input type="email" id="email" name="email" formControlName="email" class="form-control">
                    </div>
                  </div>
                </div>
                <div class="col-12 col-xs col-md col-lg col-xl">
                  <div class="row" id="addressDiv">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" id="addressDiv">
                      <h3>
                        <span class="badge btn-secondary"
                              i18n="Address label@@address">{{'ORDERS.CREATE.address' | translate}}</span>
                      </h3>
                      <input type="text" id="address" name="address" formControlName="address"
                             class="form-control">
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <h3>
                        <span class="badge btn-secondary" i18n="Country Region Code label label@@countryRegionCode">{{'ORDERS.CREATE.countryRegionCode' | translate}}</span>
                      </h3>
                      <input type="text" id="countryRegionCode" name="countryRegionCode"
                             formControlName="countryRegionCode"
                             class="form-control">
                    </div>
                  </div>
                  <div class="row" id="cityAndCodeDiv">
                    <div class="col-12 col-md" id="cityDiv">
                      <h3><span class="badge btn-secondary" i18n="City name label@@cityName">{{'ORDERS.CREATE.cityName' | translate}}</span></h3>
                      <input type="text" id="city" name="city" formControlName="city" class="form-control">
                    </div>
                    <div class="col-12 col-md-4">
                      <h3><span class="badge btn-secondary" i18n="Postal code label@@postalCode">{{'ORDERS.CREATE.postalCode' | translate}}</span></h3>
                      <input type="number" id="postalCode" name="postalCode" formControlName="postalCode"
                             class="form-control">
                    </div>
                    <div class="col-12 col-md-12">
                      <h3>
                        <span class="badge btn-secondary" i18n="Phone number label@@phoneNumber">{{'ORDERS.CREATE.phoneNumber' | translate}}</span>
                      </h3>
                      <input type="number" id="phoneNumber" name="phoneNumber" formControlName="phoneNumber"
                             class="form-control">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

  <hr>

  <div class="col-12 row" id="tableDiv" *ngIf="isOrderLoaded">
    <ag-table #table id="orderProducts" min-width="800px">
      <ag-table-header>
        <ag-table-col no-sort="true" width="15%" i18n="@@EAN">{{'ORDERS.CREATE.EAN' | translate}}</ag-table-col>
        <ag-table-col no-sort="true" width="10%" i18n="@@itemNo">{{'ORDERS.CREATE.itemNo' | translate}}</ag-table-col>
        <ag-table-col no-sort="true" i18n="Product description label@@productDesc">{{'ORDERS.CREATE.productDesc' | translate}}</ag-table-col>
        <ag-table-col no-sort="true" width="10%" i18n="Product pcb label@@productPCB">{{'ORDERS.CREATE.productPCB' | translate}}</ag-table-col>
        <ag-table-col no-sort="true" width="10%" i18n="Product unit  label@@productUnitPrice">{{'ORDERS.CREATE.productUnitPrice' | translate}}</ag-table-col>
        <ag-table-col no-sort="true" width="10%" i18n="Product desired quantity label@@productQuantity">
          {{'ORDERS.CREATE.productQuantity' | translate}}
        </ag-table-col>
        <ag-table-col no-sort="true" width="10%" i18n="Product total price label taxeless@@productTotal">
          {{'ORDERS.CREATE.productTotal' | translate}}
        </ag-table-col>
        <ag-table-col no-sort="true" width="10%">
          <button type="button" class="btn btn-success btn-icon-split" (click)="addContent(true)">
            <span class="icon text-white-50">
              <i class="fas fa-plus-square"></i>
            </span>
          </button>
        </ag-table-col>
      </ag-table-header>
      <ag-table-body i18n-empty-msg="Message when there's no data to display in table@@emptyMessageTable"
                     empty-msg="Nothing to display." formArrayName="content">
        <ag-table-row *ngFor="let orderLine of getContent().controls; let i=index" formGroupName="{{i}}" class="m-2"
                      id="element{{i}}">
          <ag-table-cell>
            <div class="search">
              <input type="text"
                     id="ean"
                     name="ean"
                     maxlength="13"
                     formControlName="ean"
                     (focusout)="onFocusOut(orderLine)"
                     (focusin)="setVisited(orderLine)"
                     class="form-control">
              <ul class="list-group dropdown" *ngIf="lastVisitedInput == orderLine"
                  style="width: 50%; z-index: 1000; position: absolute">
                <li class="list-group-item" style="width: 100%" *ngIf="researchResults.length > 0">
                  <div class="row  m-auto font-weight-bold text-primary">
                    <div class="col border-right" i18n="@@EAN">
                      {{'ORDERS.CREATE.EAN' | translate}}
                    </div>
                    <div class="col border-right" i18n="@@itemNo">
                      {{'ORDERS.CREATE.itemNo' | translate}}
                    </div>
                    <div class="col border-right" i18n="@@productDesc">
                      {{'ORDERS.CREATE.productDesc' | translate}}
                    </div>
                    <div class="col border-right" i18n="@@productPCB">
                      {{'ORDERS.CREATE.productPCB' | translate}}
                    </div>
                    <div class="col border-right" i18n="@@productUnitPrice">
                      {{'ORDERS.CREATE.productUnitPrice' | translate}}
                    </div>
                  </div>
                </li>
                <li class="list-group-item list-group-item-action " *ngFor="let item of researchResults"
                    style="width: 100%">
                  <a (click)="onProductSelect(item)" class="row  m-auto">
                    <div class="col border-right">
                      {{item['EAN']}}
                    </div>
                    <div class="col border-right">
                      {{item['itemNo']}}
                    </div>
                    <div class="col border-right">
                      {{item['description']}}
                    </div>
                    <div class="col border-right">
                      {{item['pcb']}}
                    </div>
                    <div class="col border-right">
                      <ng-container *ngIf="item['unitPrice'] !== ''">
                        {{item['unitPrice']}} €
                      </ng-container>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </ag-table-cell>
          <ag-table-cell>
            <input type="text"
                   id="itemNo"
                   name="itemNo"
                   maxlength="6"
                   formControlName="itemNo"
                   (focusout)="onFocusOut(orderLine)"
                   (focusin)="setVisited(orderLine)"
                   class="form-control">
          </ag-table-cell>
          <ag-table-cell>
            {{orderLine.get('description').value}}
          </ag-table-cell>
          <ag-table-cell>
            {{orderLine.get('pcb').value}}
          </ag-table-cell>
          <ag-table-cell>
            <ng-container *ngIf="orderLine.get('price').value !== ''">
              {{orderLine.get('price').value | number : '1.2-2'}} €
            </ng-container>
          </ag-table-cell>
          <ag-table-cell>
            <input type="number"
                   id="quantity"
                   name="quantity"
                   [step]="orderLine.get('pcb').value"
                   [min]="orderLine.get('pcb').value"
                   formControlName="quantity"
                   class="form-control ">
          </ag-table-cell>
          <ag-table-cell>
            <ng-container *ngIf="orderLine.get('price').value !== ''">
              {{orderLine.get('total').value | number : '1.2-2'}} €
            </ng-container>
          </ag-table-cell>
          <ag-table-cell>
            <button type="button" class="btn btn-danger btn-icon-split" (click)="deleteContent(i)">
              <span class="icon text-white-50">
                <i class="fas fa-trash"></i>
              </span>
            </button>
          </ag-table-cell>
        </ag-table-row>
        <ag-table-row class="m-2">
          <ag-table-cell></ag-table-cell>
          <ag-table-cell></ag-table-cell>
          <ag-table-cell></ag-table-cell>
          <ag-table-cell></ag-table-cell>
          <ag-table-cell class="font-weight-bold" i18n="Global total label@@globalTotal">
            Global Total
          </ag-table-cell>
          <ag-table-cell class="font-weight-bold">
            <ng-container *ngIf="orderForm.get('total') !== null">
              {{orderForm.get('total').value | number : '1.2-2'}} €
            </ng-container>
          </ag-table-cell>
          <ag-table-cell></ag-table-cell>
        </ag-table-row>
        <ag-table-row class="bg-transparent">
          <button type="button"
                  class="btn btn-icon-split float-right btn-info m-1"
                  (click)="getBack()">
            <span class="icon text-white-50">
              <i class="fas fa-arrow-left"></i>
            </span>
            <span class="text" i18n="Button to get back@@backFormBtn">{{'ORDERS.CREATE.backFormBtn' | translate}}</span>
          </button>
          <button type="button"
                  [ngClass]="{'btn btn-icon-split float-right m-1' : true, 'btn-secondary' : orderForm.invalid, 'btn-success': orderForm.valid}"
                  (click)="submitOrder()">
            <span class="icon text-white-50">
              <i [ngClass]="{'fas': true,'fa-times': orderForm.invalid, 'fa-check': orderForm.valid}"></i>
            </span>
            <span class="text" i18n="Button to submit form@@submitFormBtn">{{'ORDERS.CREATE.submitFormBtn' | translate}}</span>
          </button>
        </ag-table-row>
      </ag-table-body>
    </ag-table>
    <div id="endOfTable"></div>

  </div>

</form>

<ng-template #errorModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Erreur</h4>
  </div>
  <div class="modal-body">
    <p>{{error}}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-success btn-icon-split m-2"
            (click)="modal.dismiss()">
      <span class="icon text-white-50">
        <i class="fas fa-check"></i>
      </span>
      <span class="text" >OK</span>
    </button>
  </div>
</ng-template>


