import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  //Tell wherever sidebar is toggled or not
  isToggled: boolean;

  //Variable holding user authentication state and Subscription for any Observer needing propagation of variable changes
  isAuth: boolean;
  authSub: Subscription;

  constructor(private authService: AuthenticationService) { }


  ngOnInit() {
    this.isToggled = false;
    //Creation susbscription to oberseve isAuth variable state from Authentication Service variable and emit a first time
    this.authSub = this.authService.isAuthSub.subscribe(
      (value) => {
        this.isAuth = value;
      });
    this.authService.emitSubject();
  }

  ngOnDestroy() {
    this.authSub.unsubscribe();
  }

  onSignOff() {
    this.authService.SignOff();
  }

  setToggled() {
    this.isToggled = !this.isToggled;
  }
}
