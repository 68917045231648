import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {HttpClientModule, HttpClient} from '@angular/common/http';
import { RouterModule, Routes} from '@angular/router';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { SigninComponent } from './authentication/signin/signin.component';
import { OrderComponent } from './orders/create/create.component';
import { OrdersListComponent } from './orders/list/list.component';
import { AuthenticationGuardService } from './services/authentication-guard.service';
import { AuthenticationService } from './services/authentication.service';
import { CustomerService } from './services/customer.service';

import { CookieService } from 'ngx-cookie-service';
import { TopHeaderComponent } from './header/top-header/top-header.component';

// Ag-Table import
import {AgTableModule} from 'ag-table';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {Globals} from './utils/globals';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

// Defining routes

const routes: Routes = [
  { path: '' , component: SigninComponent },
  {
    path: 'orders', canActivate: [AuthenticationGuardService], children: [
      {path: 'list', component: OrdersListComponent},
      {path: ':action', component: OrderComponent}, // route for action isn't requiering an extra parameter (orders/new)
      {path: ':action/:id', component: OrderComponent}, // other routes (orders/modify/:id for example)
      {path: '', redirectTo: 'orders/list', pathMatch: 'full'}
    ]
  },
  { path: '**', redirectTo: ''}
];

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SigninComponent,
    OrderComponent,
    OrdersListComponent,
    TopHeaderComponent
  ],
    imports: [
        BrowserModule.withServerTransition({appId: 'ng-cli-universal'}),
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forRoot(routes, {anchorScrolling: 'enabled'}),
        AgTableModule,
        NgbModule,
        BrowserAnimationsModule,
        MatSnackBarModule,
        TranslateModule.forRoot({
          defaultLanguage: 'en',
          loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
          }
      })
    ],
  providers: [
    AuthenticationGuardService,
    AuthenticationService,
    CustomerService,
    CookieService,
    Globals
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
