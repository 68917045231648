import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Globals} from '../utils/globals';
import {CustomerService} from './customer.service';
import {Customer} from '../../models/customer.model';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  isAuth: boolean;
  isAuthSub = new Subject<boolean>();

  constructor(
    private router: Router,
    private cookieService: CookieService,
    private httpClient: HttpClient,
    private globals: Globals,
    private customerService: CustomerService) {
    // this.checkLoggin();
  }

/*  checkLoggin() {
    if (window.sessionStorage.getItem('isAuth')) {
      this.isAuth = true;
    } else {
      this.isAuth = false;
    }

    this.emitSubject();
  }*/

  emitSubject() {
    this.isAuthSub.next(this.isAuth);
  }

  /// <summary>
  /// Set session storage variable indicating log state or throw ex
  /// </summary>
  /// <returns></returns>
  setAuthKey() {
    const sessionStorage = window.sessionStorage;
    try {
      sessionStorage.setItem('isAuth', 'true');
      // this.cookieService.set("isAuth", "true");
      return true;
    } catch (e) {
      return e instanceof DOMException && (
        // everything except Firefox
        e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
        // acknowledge QuotaExceededError only if there's something already stored
        (sessionStorage.length !== 0);
    }
  }

  SignIn(login: string, password: string) {
    console.log(this.globals.apiRoute);
    return this.httpClient.post(
      this.globals.apiRoute + '/Customers/Authenticate/',
      {
        login: login,
        password: password
      },
      this.globals.options
    )
      .toPromise()
      .then((customer: Customer) => {
        if (this.setAuthKey()) {
          this.isAuth = true;
          this.emitSubject();
          console.log(customer);
          this.customerService.customer = customer;
          this.customerService.token = deepCopy(customer);
          return true;
        } else {
          this.isAuth = false;
          this.emitSubject();
          return false;
        }
      })
      .catch(error => {
        return error;
      });
  }

  SignOff() {
    this.httpClient.post(
      this.globals.apiRoute + '/Customers/Logout/',
      {
        customer: this.customerService.customer
      },
      this.globals.options)
      .toPromise()
      .then(resp => {
        if (resp) {
          console.log('Disconnected');
        }
      });
    this.isAuth = false;
    this.emitSubject();
    window.sessionStorage.removeItem('isAuth');

  }
}

/**
 * Deep copy function for TypeScript.
 * @param T Generic type of target/copied value.
 * @param target Target value to be copied.
 * @see Source project, ts-deepcopy https://github.com/ykdr2017/ts-deepcopy
 * @see Code pen https://codepen.io/erikvullings/pen/ejyBYg
 */
export const deepCopy = <T>(target: T): T => {
  if (target === null) {
    return target;
  }
  if (target instanceof Date) {
    return new Date(target.getTime()) as any;
  }
  if (target instanceof Array) {
    const cp = [] as any[];
    (target as any[]).forEach((v) => { cp.push(v); });
    return cp.map((n: any) => deepCopy<any>(n)) as any;
  }
  if (typeof target === 'object' && target !== {}) {
    const cp = { ...(target as { [key: string]: any }) } as { [key: string]: any };
    Object.keys(cp).forEach(k => {
      cp[k] = deepCopy<any>(cp[k]);
    });
    return cp as T;
  }
  return target;
};
