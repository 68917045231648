<div class="container-fluid">

  <div class="row">
    <h2>{{partnerName}}</h2>
  </div>

  <div class="row">
    <div class="col-12 col-lg-12 col-sm-12 col-xl-12">
      <ag-table #table id="ordersTable" [items]="ordersList">
        <ag-table-header>
          <ag-table-col filter="text"
                        field="orderID"
                        i18n-placeholder="Document Reference label/placeholder@@documentReference"
                        placeholder="{{'ORDERS.LIST.documentReference' | translate}}"
                        mode="startsWith"
                        i18n="@@documentReference">
            {{'ORDERS.LIST.documentReference' | translate}}
          </ag-table-col>
          <ag-table-col filter="date"
                        field="orderDate"
                        date-format="dd/MM/yyyy"
                        placeholder="{{'TABLE.dateFormatPlaceHolder' | translate}}"
                        width="15%"
                        i18n="Order date column title for customer's orders list table@@orderDateColumnTitle">
            {{'ORDERS.LIST.orderDateColumnTitle' | translate}}
          </ag-table-col>
          <ag-table-col filter="date"
                        field="shippingDate"
                        date-format="dd/MM/yyyy"
                        placeholder="{{'TABLE.dateFormatPlaceHolder' | translate}}"

                        width="15%"
                        i18n="Shipping date column title for customer's orders list table@@shippingDateColumnTitle">
            {{'ORDERS.LIST.shippingDateColumnTitle' | translate}}
          </ag-table-col>
          <ag-table-col width="10%"
                        no-sort="true"
                        i18n="@@totalColumnTitle"
          >
            {{'ORDERS.LIST.totalColumnTitle' | translate}}
          </ag-table-col>
          <ag-table-col filter="select"
                        field="validated"
                        [options]="[
                    {text: statusValidated, value: 'True'},
                    {text: statusNotValidated, value: 'False'}
                  ]"
                        width="10%"
                        i18n="@@stateColumnTitle"
          >
            {{'ORDERS.LIST.stateColumnTitle' | translate}}
          </ag-table-col>
          <ag-table-col no-sort="true"
                        width="15%"
                        i18n="@@actionsColumnTitle"
          >
            <div>{{'ORDERS.LIST.actionsColumnTitle' | translate}}</div>
            <div>
              <a routerLink="/orders/new">
                <button type="button" class="btn btn-success btn-icon-split icon">
                              <span class="icon text-white-50">
                <i class="fas fa-plus-square"></i>
                              </span>
                </button>
              </a>
            </div>
          </ag-table-col>
        </ag-table-header>
        <ag-table-body i18n-empty-msg="Message when there's no data to display@@emptyMessageTable"
                       empty-msg="{{'TABLE.emptyMessageTable' | translate}}">

          <ag-table-row *ngFor="let order of table.items; let i=index"
                        [ngClass]="{'border-left-warning': !order.validated, 'border-left-success': order.validated, 'm-2' : true}"
          >
            <ag-table-cell>
              {{order.documentReference}}
            </ag-table-cell>
            <ag-table-cell>
              {{order.orderDate | date : 'dd/MM/yyyy'}}
            </ag-table-cell>
            <ag-table-cell>
              {{order.shippingDate | date : 'dd/MM/yyyy'}}
            </ag-table-cell>
            <ag-table-cell>
              {{order.total | number:'1.2-2'}} €
            </ag-table-cell>
            <ag-table-cell>
              <i
                [ngClass]="{'fas fa-circle': true, 'text-warning': !order.validated, 'text-success': order.validated}"></i>
            </ag-table-cell>
            <ag-table-cell>
              <button type="button" class="btn btn-danger btn-icon-split m-2"
                      (click)="setDelete(order.documentReference)">
             <span class="icon text-white-50">
               <i class="fas fa-trash"></i>
             </span>
              </button>
              <a routerLink="/orders/modify/{{order.documentReference}}">
                <button type="button" class="btn btn-secondary btn-icon-split m-2">
             <span class="icon text-white-50">
              <i class="fas fa-pen"></i>
             </span>
                </button>
              </a>
            </ag-table-cell>
          </ag-table-row>
        </ag-table-body>

      </ag-table>
    </div>
  </div>
</div>

<ng-template #modalConfirm let-modal>
  <div class="modal-header">
    <h4 class="modal-title" i18n="Delete confirmation@@delConf">{{'ORDERS.LIST.delConf' | translate}}{{lastID}}?</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');clear()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p i18n="Delete order text@@deleteOrderText">{{'ORDERS.LIST.deleteOrderText' | translate}}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-success btn-icon-split m-2"
            (click)="modal.dismiss('Cross click');onDeleteOrder()">
             <span class="icon text-white-50">
              <i class="fas fa-check"></i>
             </span>
      <span class="text" i18n="Delete confirmation Yes@@toastYes">{{'ORDERS.LIST.toastYes' | translate}}</span>
    </button>
    <button type="button" class="btn btn-danger btn-icon-split m-2" (click)="modal.dismiss('Cross click');clear()">
             <span class="icon text-white-50">
               <i class="fas fa-times"></i>
             </span>
      <span class="text" i18n="Delete cancel No@@toastNo">{{'ORDERS.LIST.toastNo' | translate}}</span>
    </button>
  </div>
</ng-template>


