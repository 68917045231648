export class Product {

  //constructor(
  //   EAN: string,
  //   description: string,
  //   pcb: number,
  //   price: number,
  //)
  constructor(
    public EAN?: string,
    public description?: string,
    public pcb?: number,
    public unitPrice?: number,
    public itemNo?: number
  ) {
    if (EAN == null)
      this.EAN = "";
    if (description == null)
      this.description = "";
    if (pcb == null)
      this.pcb = 0;
    if (unitPrice == null)
      this.unitPrice = 0;
    if (itemNo == null)
      this.itemNo = 0;
  }
}
