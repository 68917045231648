import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import {CanActivate, Router, CanDeactivate} from '@angular/router';
import { Observable } from 'rxjs';
import { SigninComponent } from '../authentication/signin/signin.component';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuardService implements CanActivate {

  constructor(private authService: AuthenticationService, private router: Router) {
  }

  canActivate()
    : Observable<boolean> | Promise<boolean> | boolean {
    // si autorisé resolve avec true si non autorisé resolve false parce que ce n'est pas une erreur mais une interdiction
    return new Promise(
      (resolve, reject) => {
        const isAuth = this.authService.isAuth;
        if (isAuth) {
          // console.log("Accès autorisé");
          resolve(true);
        }
        else {
          this.router.navigate(['auth', 'signin']);
          // console.log("Accès interdit");
          resolve(false);
        }
      });
  }
}
