import { Address } from './address.model';

export class Customer {
  constructor(
    public customerNo?: string,
    public name?: string,
    public name2?: string,
    public searchName?: string,
    public address?: string,
    public address2?: string,
    public city?: string,
    public postalCode?: string,
    public countryRegionCode?: string,
    public customerPriceGroup?: string,
    public phoneNumber?: string,
    public lddCustomerPriceGroup?: string,
    public gln?: string,
    public username?: string,
    public shipToAddresses?: Address[]
  ) {
    if (name == null) {
      this.name = '';
    }
    if (customerNo == null) {
      this.customerNo = '';
    }
    if (name2 == null) {
      this.name2 = '';
    }
    if (searchName == null) {
      this.searchName = '';
    }
    if (address == null) {
      this.address = '';
    }
    if (address2 == null) {
      this.address2 = '';
    }
    if (city == null) {
      this.city = '';
    }
    if (customerPriceGroup == null) {
      this.customerPriceGroup = '';
    }
    if (phoneNumber == null) {
      this.phoneNumber = '';
    }
    if (lddCustomerPriceGroup == null) {
      this.lddCustomerPriceGroup = '';
    }
    if (gln == null) {
      this.gln = '';
    }
    if (postalCode == null) {
      this.postalCode = '';
    }
    if (countryRegionCode == null) {
      this.countryRegionCode = '';
    }
    if (shipToAddresses == null) {
      this.shipToAddresses = [];
    }
  }
}
