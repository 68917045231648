import {Injectable} from '@angular/core';
import {Order} from '../../models/order.model';
import {Subject} from 'rxjs';
import {Address} from '../../models/address.model';
import {Customer} from '../../models/customer.model';
import {Product} from '../../models/product.model';
import {OrderLine} from '../../models/orderLine.model';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Globals} from '../utils/globals';
import {TranslateService} from "@ngx-translate/core";


@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  token: Customer;
  customer: Customer;
  orders: Order[];

  ordersSubject: Subject<Order[]>;

  constructor(private httpClient: HttpClient,
              private globals: Globals,
              private translate: TranslateService) {

    // Initializing variables
    this.ordersSubject = new Subject<Order[]>();
    this.token = new Customer();
    this.customer = new Customer();
    this.orders = [];
  }

  // Emit new orders object
  emitOrdersSubject() {
    this.ordersSubject.next(this.orders);
  }

  // Get orders for a customer
  getCustomerOrdersList() {
    this.httpClient.post(
      this.globals.apiRoute + '/Orders/GetOrdersList',
      {
        customer: this.token
      },
      this.globals.options)
      .toPromise()
      .then(orders => {

        const newOrders: Order[] = [];
        let currentOrder: Order;
        let date: string;
        let total: number;
        let partner: any;
        let address: Address;

        if (orders === 'None') {
          return;
        }

        for (const content in orders) {
          currentOrder = new Order();
          // Empty the default line
          currentOrder.lines = [];

          // Get dates from order headers
          date = orders[content].header.deliveryDate.split(' ')[0].split('/');
          console.log(date[2])
          currentOrder.shippingDate = new Date(Number(date[2]), Number(date[1])-1, Number(date[0]));
          date = orders[content].header.documentDate.split(' ')[0].split('/');
          currentOrder.orderDate = new Date(Number(date[2]), Number(date[1])-1, Number(date[0]));
          currentOrder.documentReference = orders[content].header.documentReference;
          // Get name of the EDI file (stored in a COM line
          for (const obj in orders[content].comments) {
            if (orders[content].comments[obj].commentType === 'FILENAME') {
              currentOrder.filename = orders[content].comments[obj].comments;
              break;
            }
          }

          if (orders[content].header.commandType == "0") {
            // Get address from partner DP line
            for (const obj in orders[content].partner) {
              if (orders[content].partner[obj].partnerType === 'DP') {
                partner = orders[content].partner[obj];
              }
            }

            // If it's custom address we retrieve fields else we only take address name
            address = new Address();

            address.name = partner.namePartner;
            address.city = partner.city;
            address.postalCode = partner.postalCode;
            address.address = partner.address;

            for (const obj in orders[content].partner) {
              if (orders[content].partner[obj].partnerType === 'UC') {
                partner = orders[content].partner[obj];
                address.name = 'Personnalisée';
                address.city = partner.city;
                address.postalCode = partner.postalCode;
                address.address = partner.address;
                address.fullname = partner.namePartner;
                address.countryRegionCode = partner.countryCode;
                for (const sub_obj in orders[content].contacts) {
                  if (orders[content].contacts[sub_obj].contactType === 'OC') {
                    partner = orders[content].contacts[sub_obj];
                    address.phoneNumber = partner.phone;
                    address.email = partner.mail;
                  }
                }
              }
            }

            currentOrder.shipToAddresses = deepCopy(address);

            /*          // Get document Reference from partner BY
                      for (const obj in orders[content].partner) {
                        if (orders[content].partner[obj].partnerType === 'BY') {
                          partner = orders[content].partner[obj];
                        }
                      }
                      currentOrder.documentReference = partner.documentReference;*/

            // Loop over each order lines
            total = 0.0;
            for (const line in orders[content].lines) {
              // const subtotal = orders[content].lines[line].quantity * +orders[content].lines[line].unitPrice.replace(',', '.');
              currentOrder.lines.push(new OrderLine(
                new Product(
                  orders[content].lines[line].ean,
                  orders[content].lines[line].description,
                  Number(orders[content].lines[line].pcb),
                  Number(orders[content].lines[line].unitPrice.replace(',', '.')),
                  orders[content].lines[line].itemNo
                ),
                Number(orders[content].lines[line].quantity),
                Number(orders[content].lines[line].taxlessPrice)
              )
              );

              // total += subtotal;
            }
            currentOrder.total = Number(orders[content].linesAmount);
          }
          newOrders.push(deepCopy(currentOrder));
        }

        this.orders = deepCopy(newOrders);
        this.emitOrdersSubject();
        console.log(orders);
      })
      .catch((reason: HttpErrorResponse) => {
        console.log(reason);
    });
  }

  // Retrieve all customer addresses
  getCustomerShipToAddresses() {
    this.customer.shipToAddresses = [];
    return this.httpClient.post(
      this.globals.apiRoute + '/Customers/GetAddresses',
      {
        customer: this.token
      },
      this.globals.options)
      .toPromise()
      .then((addresses: any) => {
        for (const address in addresses) {

          const currentAddress = new Address();
          currentAddress.name = addresses[address].name;
          currentAddress.address = addresses[address].address;
          currentAddress.countryRegionCode = addresses[address].countryRegionCode;
          currentAddress.postalCode = addresses[address].postalCode;
          currentAddress.gln = addresses[address].gln;
          currentAddress.city = addresses[address].city;
          this.customer.shipToAddresses.push(currentAddress);
        }
        console.log(this.customer.shipToAddresses);
      });
  }

  getSingleOrderLocal(documentReference: string) {
    return  this.orders.find(ord => ord.documentReference === documentReference);
  }

  getSingleOrder(documentReference: string) {
    const order =  this.orders.find(ord => documentReference === ord.documentReference);

    if (order !== undefined) {

      return this.httpClient.post(
        this.globals.apiRoute + '/Orders/GetSingleOrder/',
        {
          customer: this.token,
          filename: order.filename
        },
        this.globals.options
      )
        .toPromise()
        .then(() => {
          return order;
        });
    }

    return null;
  }

  addOrder(order: Order) {
    this.orders.push(deepCopy(order));
    this.emitOrdersSubject();
  }

  // Delete order from local orders and call API to delete the file
  deleteOrder(order: Order) {
    const orderTemp = this.orders.findIndex(ord => ord.documentReference === order.documentReference);
    if (orderTemp !== -1) {
      this.getSingleOrder(order.documentReference)
        .then(order => {
          // console.log('Deleted ', order);
        });
      this.orders.splice(orderTemp, 1);
      this.emitOrdersSubject();
    }
  }

  modifyOrder(order: Order, index: number) {
      this.orders[index] = deepCopy(order);
      this.emitOrdersSubject();
  }

  submitOrder(order: Order) {
    const index = this.orders.findIndex(ord => order.documentReference === ord.documentReference);

    if (index !== -1) {
      this.modifyOrder(order, index);
    } else {
      this.addOrder(order);
    }
    this.httpClient.post(
      this.globals.apiRoute + '/Orders/SubmitOrder',
      {
        customer : this.token,
        order : deepCopy(order)
      },
      this.globals.options
    )
      .toPromise()
      .then((saved: boolean) => {
        if (saved) {
          console.log('Sent :', order);
        } else {
          console.log('Error :' , order);
        }
      }).catch(erorr => console.log('error'));
  }

  changeLang(lang: string) {
    this.translate.use(lang);
  }

}

export const deepCopy = <T>(target: T): T => {
  if (target === null) {
    return target;
  }
  if (target instanceof Date) {
    return new Date(target.getTime()) as any;
  }
  if (target instanceof Array) {
    const cp = [] as any[];
    (target as any[]).forEach((v) => { cp.push(v); });
    return cp.map((n: any) => deepCopy<any>(n)) as any;
  }
  if (typeof target === 'object' && target !== {}) {
    const cp = { ...(target as { [key: string]: any }) } as { [key: string]: any };
    Object.keys(cp).forEach(k => {
      cp[k] = deepCopy<any>(cp[k]);
    });
    return cp as T;
  }
  return target;
};
