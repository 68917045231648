import { Product } from "./product.model";

export class OrderLine {

  //constructor(
  //   product: Product,
  //   amount: number,
  //   total: number
  //)
  constructor(
    public product?: Product,
    public quantity?: number,
    public total?: number
  ) {
    if (product == null && quantity == null && total == null) {
      this.product = new Product();
      this.quantity = 0;
      this.total = 0;
    }
  }
}
